<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Welder'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8"
                  id="nama_welder_label" label="Nama Welder :" label-for="nama_welder">
                  <b-form-input id="nama_welder" v-model="form.nama_welder" type="text"
                    placeholder="Enter Text..."></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" id="welder_id_label"
                  label="Welder ID :" label-for="welder_id">
                  <b-form-input id="welder_id" v-model="form.welder_id" type="text"
                    placeholder="Enter Text..."></b-form-input>
                </b-form-group>
                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" id="welder_id_label"
                  label="No HP :" label-for="no_hp">
                  <b-form-input id="no_hp" v-model="form.no_hp" type="text" placeholder="Enter Text..."></b-form-input>
                </b-form-group>
                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" id="tgl_label"
                  label="Tanggal Lahir :" label-for="tgl_lahir">
                  <b-form-input id="tgl_lahir" v-model="form.tgl_lahir" type="date"
                    placeholder="Enter Text..."></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" id="alamat_label"
                  label="Alamat :" label-for="alamat">
                  <vue-editor id="alamat" v-model="form.alamat" :editorToolbar="customToolbar"></vue-editor>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8"
                  id="perusahaan_label" label="Perusahaan :" label-for="perusahaan">
                  <b-form-input id="perusahaan" v-model="form.perusahaan" type="text"
                    placeholder="Enter Text..."></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" v-if="foto">
                  <b-img :src="urlStorage + '/images/welder/' + foto" fluid></b-img>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" id="foto_label"
                  label="Foto :" label-for="foto">
                  <b-form-file id="foto" v-model="form.foto" placeholder="Choose a photo or drop it here..."
                    drop-placeholder="Drop file here..."></b-form-file>
                </b-form-group>

                <b-alert class="pl-2" variant="danger" :fade="true" v-model="showDismissibleAlert">
                  <span v-for="(val, index) in errors" :key="index">
                    <h6>{{ index.replace(/_/g, ' ').toUpperCase() + ':' }}</h6>
                    <ul>
                      <li v-for="(arr, i) in val" :key="i">
                        {{ arr }}
                      </li>
                    </ul>
                    <br />
                  </span>
                </b-alert>

                <b-button variant="outline-dark" :disabled="loading" @click="$router.push('/kualifikasi_las/welder')">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'

import { VueEditor } from 'vue2-editor'
import $ from 'jquery'

import * as moment from 'moment'
import 'moment/locale/id'

export default {
  mixins: [validationMixin],
  name: 'editwelder',
  data() {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [
          {
            list: 'ordered',
          },
          {
            list: 'bullet',
          },
          {
            list: 'check',
          },
        ],
        [
          {
            align: '',
          },
          {
            align: 'center',
          },
          {
            align: 'right',
          },
          {
            align: 'justify',
          },
        ],
      ],
      form: {
        nama_welder: null,
        welder_id: null,
        tgl_lahir: null,
        alamat: null,
        perusahaan: null,
        foto: null,
        no_hp: null,
      },
      foto: null,
      loading: false,
      errors: [],
      showDismissibleAlert: false,
      id: 0,
      urlStorage: '',
    }
  },
  components: {
    KTCodePreview,
    VueEditor,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Welder', route: '/kualifikasi_las/welder' },
      { title: 'Edit Welder' },
    ])
  },
  created() {
    var self = this
    this.id = this.$route.params.id

    self.urlStorage = ApiService.urlStorage()
    ApiService.get('/kualifikasi_las/welder/edit/' + self.id)
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.form.nama_welder = data.data.nama_welder
          self.form.welder_id = data.data.welder_id
          self.form.tgl_lahir = data.data.tgl_lahir
          self.form.alamat = data.data.alamat
          self.form.no_hp = data.data.no_hp
          self.form.perusahaan = data.data.perusahaan
          self.foto = data.data.foto
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    moment: function (date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD LTS').format('dddd, LL')
    },
    onSubmit() {
      // event.preventDefault()
      var self = this
      self.loading = true
      const nama_welder = self.form.nama_welder
      const welder_id = self.form.welder_id
      const tgl_lahir = self.form.tgl_lahir
      const alamat = self.form.alamat
      const perusahaan = self.form.perusahaan
      const foto = self.form.foto
      const no_hp = self.form.no_hp

      const dataku = new FormData()
      if (nama_welder) dataku.append('nama_welder', nama_welder)
      if (welder_id) dataku.append('welder_id', welder_id)
      if (tgl_lahir) dataku.append('tgl_lahir', tgl_lahir)
      if (alamat) dataku.append('alamat', alamat)
      if (perusahaan) dataku.append('perusahaan', perusahaan)
      if (foto) dataku.append('foto', foto)
      if (no_hp) dataku.append('no_hp', no_hp)

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/kualifikasi_las/welder/update/' + self.id,
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function (response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/kualifikasi_las/welder')
          } else if (response.status == 'validation_error') {
            self.errors = response.errors
            self.showDismissibleAlert = true
            self.loading = false
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
